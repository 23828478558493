export default [
  {
    id: 0,
    name: 'churn_mrr',
    description: 'Ingresos mensuales recurrentes perdidos por cancelaciones durante el período.',
    type: {
      kpi: {
        displayComparative: true
      },
      graph: {
        class: 'line'
      }
    },
    currency: true,
    category: 'MRR',
    active: true
  },
  {
    id: 1,
    name: 'cancellation_mrr_by_plan',
    description: 'Ingresos mensuales recurrentes perdidos debido a cancelaciones durante el período por cada plan.',
    type: ['kpi', 'graph'],
    category: '',
    active: false
  },
  {
    id: 2,
    name: 'net_growth_mrr',
    description: 'Aumento o disminución neto de los ingresos recurrentes mensuales durante el período.',
    type: {
      kpi: {
        displayComparative: true
      },
      graph: {
        labels: ['Nuevo', 'Expansión', 'Contracción', 'Abandono', 'Crecimiento neto'],
        colors: ['#3F51B5', '#9FA8DA', '#FFECB3', '#FFC107', '#1A237E'],
        class: 'barStacked'
      }
    },
    currency: true,
    category: 'MRR',
    active: true
  },
  {
    id: 3,
    name: 'abandonment_breakdown_report_by_record_date',
    description: 'Desglose del abandono durante el período especificado en varios intervalos de tiempo.',
    type: ['table'],
    category: '',
    active: false
  },

  {
    id: 4,
    name: 'signups',
    description: 'Número total de inscripciones durante el período.',
    type: ['kpi', 'graph'],
    category: '',
    active: false
  },
  {
    id: 5,
    name: 'total_billing',
    description: 'Valor total de las facturas generadas durante el período.',
    type: {
      kpi: {
        displayComparative: true
      },
      graph: {
        class: 'line'
      }
    },
    currency: true,
    category: 'Facturación',
    active: true
  },
  {
    id: 6,
    name: 'total_billing_non_recurring',
    description: 'Ingresos no recurrentes totales facturados en un período en particular.',
    type: {
      kpi: {
        displayComparative: true
      },
      graph: {
        class: 'line'
      }
    },
    currency: true,
    category: 'Facturación',
    active: true
  },
  {
    id: 7,
    name: 'total_tax',
    description: 'Total de impuestos aplicados a las facturas durante el período de tiempo.',
    type: {
      kpi: {
        displayComparative: true
      },
      graph: {
        class: 'line'
      }
    },
    currency: true,
    category: 'Impuestos',
    active: true
  },
  {
    id: 8,
    name: 'total_cancelled_billing',
    description: 'Valor total de todas las facturas anuladas durante el período.',
    type: {
      kpi: {
        append: 'CLP',
        displayComparative: true
      },
      graph: {
        class: 'line'
      }
    },
    currency: true,
    category: 'Facturación',
    active: true
  },
  {
    id: 9,
    name: 'net_billing',
    description: 'Valor total de las facturas después de excluir el valor de las notas de crédito emitidas y los impuestos.',
    type: {
      kpi: {
        displayComparative: true
      },
      graph: {
        class: 'line'
      }
    },
    currency: true,
    category: 'Facturación',
    active: true
  },
  {
    id: 10,
    name: 'total_receivables_overdue',
    description: 'Total por cobrar de facturas que han superado la fecha de vencimiento del pago.',
    type: {
      kpi: {
        displayComparative: false
      }
    },
    currency: true,
    category: 'Cobranza',
    active: true
  },
  {
    id: 11,
    name: 'total_receivables_current_due',
    description: 'Total por cobrar de facturas que no han superado la fecha de vencimiento.',
    type: {
      kpi: {
        displayComparative: false
      }
    },
    currency: true,
    category: 'Cobranza',
    active: true
  },
  {
    id: 12,
    name: 'overdue_billing_recovery_breakdown',
    description: 'Agrupación de facturas no pagadas según el número de días vencidos.',
    type: {
      table: {
        headers: [
          { text: 'Días', value: 'label', class: 'font-weight-bold', sortable: false },
          { text: 'Número de facturas', value: 'data', class: 'font-weight-bold', sortable: false }
        ]
      }
    },
    currency: false,
    category: 'Cobranza',
    active: true
  },
  {
    id: 13,
    name: 'Desglose de recuperación de facturas vencidas',
    description: 'Tabla que muestra las facturas que se pagaron después de la fecha de vencimiento, agrupadas por número de días que se tomó en pagar después de la fecha de vencimiento.',
    type: ['table'],
    category: '',
    active: false
  },
  {
    id: 14,
    name: 'Notas de crédito totales',
    description: 'Valor total de las notas de crédito emitidas durante el período.',
    type: ['kpi', 'graph'],
    category: '',
    active: false
  },
  {
    id: 15,
    name: 'Fuga de ingresos por cupón de descuento',
    description: 'Desglose de la pérdida de ingresos por cada cupón de descuento.',
    type: ['graph'],
    category: '',
    active: false
  },
  {
    id: 16,
    name: 'Resumen de fugas de ingresos: cupones',
    description: 'Resumen de la cantidad de veces que se canjeó un cupón y el valor total que se le atribuyó.',
    type: ['table'],
    category: '',
    active: false
  },
  {
    name: 'Resumen de ingresos por complementos recurrentes',
    description: 'Desglose de los ingresos generados por complementos recurrentes.',
    type: ['table'],
    category: '',
    active: false
  },
  {
    id: 17,
    name: 'Resumen de ingresos por complementos',
    description: 'Desglose de los ingresos generados por complementos no recurrentes.',
    type: ['table'],
    category: '',
    active: false
  },
  {
    id: 18,
    name: 'Clientes activos totales',
    description: '',
    type: ['kpi', 'graph'],
    category: '',
    active: false
  },
  {
    id: 19,
    name: 'Flujo de caja recurrente',
    description: 'Valor total de los pagos recibidos contra facturas recurrentes.',
    type: ['kpi', 'graph'],
    category: '',
    active: false
  },
  {
    id: 20,
    name: 'Flujo de caja no recurrente',
    description: 'Valor total de los pagos recibidos contra facturas no recurrentes.',
    type: ['kpi', 'graph'],
    category: '',
    active: false
  },
  {
    id: 21,
    name: 'Flujo de caja bruto',
    description: 'Total de pagos recurrentes y no recurrentes recibidos durante el período.',
    type: ['kpi', 'graph'],
    category: '',
    active: false
  },
  {
    id: 22,
    name: 'Reembolsos',
    description: 'Valor total de los reembolsos realizados a los clientes durante el período.',
    type: ['kpi', 'graph'],
    category: '',
    active: false
  },
  {
    id: 23,
    name: 'Reembolsos por Gateway',
    description: 'Valor total de los reembolsos realizados a los clientes durante el período, segmentado por pasarela de pagos.',
    type: ['kpi', 'graph'],
    category: '',
    active: false
  },
  {
    id: 24,
    name: 'Reembolsos por método de pago',
    description: 'Valor total de los reembolsos realizados a los clientes durante el período, segmentado por método de pago.',
    type: ['kpi', 'graph'],
    category: '',
    active: false
  },
  {
    id: 25,
    name: 'Número de transacciones de pago',
    description: 'Número total de transacciones de pago durante el período.',
    type: ['kpi', 'graph'],
    category: '',
    active: false
  },
  {
    id: 26,
    name: 'Número de transacciones de reembolso',
    description: 'Número total de transacciones de reembolso durante el período.',
    type: ['kpi', 'graph'],
    category: '',
    active: false
  },
  {
    id: 27,
    name: 'Flujo de caja bruto por Gateway',
    description: 'Valor total de los pagos recibidos durante el período segmentados por pasarela de pago.',
    type: ['graph'],
    category: '',
    active: false
  },
  {
    id: 28,
    name: 'Flujo de caja bruto por método de pago',
    description: 'Valor total de los pagos recibidos durante el período segmentados por método de pago.',
    type: ['graph'],
    category: '',
    active: false
  },
  {
    id: 29,
    name: 'Resumen de transacciones de pago',
    description: 'Un resumen de las transacciones de pago exitosas, fallidas, anuladas, en progreso y de tiempo de espera mes a mes.',
    type: ['graph'],
    category: '',
    active: false
  },
  {
    id: 30,
    name: 'Flujo de efectivo neto',
    description: 'Neto de flujo de caja bruto y reembolsos durante el período.',
    type: ['graph'],
    category: '',
    active: false
  },
  {
    id: 31,
    name: 'Resumen de transacciones de reembolso',
    description: 'Un resumen de las transacciones de reembolso exitosas, fallidas, anuladas, en curso y por tiempo de espera, mes a mes.',
    type: ['graph'],
    category: '',
    active: false
  },
  {
    id: 32,
    name: 'Número de transacciones de pago anuladas',
    description: 'Número total de transacciones de pago anuladas durante el período.',
    type: ['kpi', 'graph'],
    category: '',
    active: false
  },
  {
    id: 33,
    name: 'Número de transacciones de pago fallidas por Gateway',
    description: 'Número total de transacciones de pago fallidas durante el período segmentadas por Gateway',
    type: ['graph'],
    category: '',
    active: false
  },
  {
    id: 34,
    name: 'Número de transacciones de pago fallidas por método de pago',
    description: 'Número total de transacciones de pago fallidas durante el período segmentadas por método de pago.',
    type: ['graph'],
    category: '',
    active: false
  },
  {
    id: 35,
    name: 'Número de transacciones de reembolso fallidas',
    description: 'Número total de transacciones de reembolso fallidas durante el período.',
    type: ['kpi', 'graph'],
    category: '',
    active: false
  },
  {
    id: 36,
    name: 'Tarjetas caducadas',
    description: 'Este es el número total de tarjetas de crédito que vencieron en los últimos seis meses.',
    type: ['table'],
    category: '',
    active: false
  },
  {
    id: 37,
    name: 'Tarjetas a punto de expirar',
    description: 'Este es el número total de tarjetas de crédito que vencerán en los siguientes seis meses.',
    type: ['table'],
    category: '',
    active: false
  },
  {
    id: 38,
    name: 'Cancelación de MRR por país',
    description: 'Ingresos mensuales recurrentes perdidos por cancelaciones durante el período segmentado por país.',
    type: ['graph'],
    category: '',
    active: false
  },
  {
    id: 39,
    name: 'Resumen de impuestos por nombre de impuestos',
    description: '',
    type: ['table'],
    category: '',
    active: false
  },
  {
    id: 40,
    name: 'Resumen de errores de pagos',
    description: 'Número total de transacciones de error segmentadas por código de error mientras se procesa una transacción de pago.',
    type: ['table'],
    category: '',
    active: false
  },
  {
    id: 41,
    name: 'Resumen de errores de reembolsos',
    description: 'Número total de transacciones de error segmentadas por código de error mientras se procesa un reembolso.',
    type: ['table'],
    category: '',
    active: false
  },
  {
    id: 42,
    name: 'total_receivables',
    description: 'Total por cobrar de facturas no vencidas y vencidas.',
    type: {
      kpi: {
        displayComparative: false
      }
    },
    currency: true,
    category: 'Cobranza',
    active: true
  }
]
